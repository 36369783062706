export default {
  apiStatus: {
    cannotConnect: 'cannot-connect',
    timeout: 'timeout',
    server: 'server',
    unknown: 'unknown',
    unauthorized: 'unauthorized',
    forbidden: 'forbidden',
    notFound: 'not-found',
    rejected: 'rejected',
    CANCEL_ERROR: 'CANCEL_ERROR',
    ok: 'ok'
  },
  fetchingStatus: {
    pending: 'pending',
    ok: 'ok',
    idle: 'idle',
    error: 'error',
    done: 'done',
    dataError: 'dataError',
    searching: 'searching'
  },
  DEFAULT_CLUB_PK: 'tw7kxtt',
  DEFAULT_ADMIN_PK: 'to5iscl',
  TOOLKIT_TYPE: {
    enterprise: 'ENTERPRISE',
    club: 'CLUB',
    smallBusinessToolkit: 'SMALL_BUSINESS_TOOLKIT'
  }
}

export const DEFAULT_READ_TIME = 3

export const CONTENT_QUERY_STRING = {
  TRANSLATION: 'lang',
  PLAYLIST_ID: 'playlistId',
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  FROM: 'from'
}

export const ASSESSMENT_STATUS = {
  NOT_AUTHORIZED: 'Not Authorized',
  NOT_STARTED: 'Not Started',
  STARTED: 'Started',
  COMPLETED: 'Completed'
}

export const DOCUMENT_ALIASES = {
  A11Y: 'a11y'
}

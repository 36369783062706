import { MSA_ACCESS } from './features'
import { ADMIN, CLIENT_MANAGER } from '../../services/cognito/userGroups'
/**
 * For feature "msa:access" the user roles "clientManager" and "admin" should access to MSA as default
 */
const defaultUserGroupsToAccessMSA = [ADMIN, CLIENT_MANAGER]

export const verifyFeatureAccess = (features, feature, userGroup, hasContentHubAccess) => {
  const access = features ? features[feature] : false

  // MSA_ACCESS is a special case where if the feature is not present, we assume access is not granted
  // This means that undefined cannot be returned as true
  if (feature === MSA_ACCESS) {
    return hasContentHubAccess
      ? defaultUserGroupsToAccessMSA.includes(userGroup) ? true : access
      : true
  }

  if (access === undefined || access === true) {
    return true
  }

  return false
}
